<template>
  <div class="">
    <div
      @click="handleClick"
      class="location-card bg-white rounded-md overflow-hidden mb-5"
    >
      <div
        class="location-card__banner w-100"
        :style="{
          backgroundImage: `url(${image})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundColor: 'var(--primary)',
          height: '10rem',
        }"
      />
      <div class="location-card__details p-4 h-100">
        <div class="d-flex justify-content-between align-items-center">
          <div class="location-name text-sm font-weight-bold text-black">
            {{ location.name }}
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <div class="bg-tertiary--light py-1 px-2 text-sm-2 rounded-sm mr-2">
            {{ location.status }}
          </div>
          <div class="bg-tertiary--light py-1 px-2 text-sm-2 rounded-sm mr-2">
            0 plot sold
          </div>
        </div>
        <!-- <div class="d-flex justify-content-between align-items-center mt-4">
          <div>
            <div class="text-sm-2">Sales Value</div>
            <div class="text-primary text-sm">N12,000,000</div>
          </div>
          <div class="text-right">
            <div class="text-sm-2">Total Paid</div>
            <div class="text-primary text-sm text-black">N12,000,000</div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      required: true,
    },
    isProperty: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    image() {
      if (this.location.images && this.location.images[0]) {
        return this.location.images[0]
      }
      return '' 
    }
  },
  methods: {
    handleClick() {
      // if (!this.isProperty) {
      //   this.globalRouter('properties', { locationId: this.location.id });
      // } else {
      //   this.globalRouter('properties.details', {
      //     propertyId: this.location.id,
      //   });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.location-card {
  min-height: 20rem;
}

.location-name {
  // width: 45%;
}
</style>

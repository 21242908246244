<template>
  <div v-if="canView">
    <div class="" v-if="loading">
      <b-row>
        <b-col cols="3" class="mt-3" v-for="i in 8" :key="i">
          <b-skeleton-img no-aspect height="250px"></b-skeleton-img>
        </b-col>
      </b-row>
    </div>
    <b-row class="mt-5" v-if="!loading && locations.length > 0">
      <b-col v-if="canCreate" cols="2" class="mb-4">
        <rm-base-button
          class="ml-auto"
          text="New Location"
          v-b-modal.modal-lg-new-location
          bgColor="primary"
          textColor="white"
          ><rm-base-icon slot="preppend-icon" class="mr-2" name="export-icon"
        /></rm-base-button>
      </b-col>
      <b-col cols="10"></b-col>
      <b-col cols="3" :key="ix" v-for="(location, ix) in locations">
        <rm-location-card :location="location" @completed="fetchLocations" />
      </b-col>
      <b-col cols="12" class="mt-4">
        <b-pagination
          v-model="currentPage"
          @change="fetchLocations"
          :current-page="currentPage"
          :total-rows="totalRows"
          :per-page="limit"
          :items="locations"
        ></b-pagination>
      </b-col>
    </b-row>
    <rm-base-modal id="new-location" size="lg">
      <rm-new-location @completed="fetchLocations" />
    </rm-base-modal>
  </div>
  <div v-else>
    <p>You do not have access to this content</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import RmLocationCard from './components/RmLocationCard.vue';
import RmNewLocation from './components/RmNewLocation.vue';
// import RmUpload from './components/RmUpload.vue';

export default {
  components: { RmLocationCard, RmNewLocation, },
  data: () => ({
    loading: false,
    currentPage: 1,
    limit: 8,
    offset: 0,
    totalRows: 1,
    locations: [],
  }),
  methods: {
    async fetchLocations(page) {
      const loader = this.$loading.show();
      try {
        let results = await this.$store.dispatch('locations/fetchLocations', {
          limit: this.limit,
          page: page ?? 1,
        });

        loader.hide();
        // console.log(results);
        this.locations = results.rows;
        this.totalRows = results.paging.total;
      } catch (error) {
        loader.hide();
        console.log(error);
      }
    },
  },
  computed: {
    ...mapState('auth', ['userObj']),
    userRoles() {
      return  this.userObj.role.scopes ?? []
    },
    canView(){
      if (this.userRoles.includes('location.read')){
        return true
      } else {
        return false
      }
    },
    canCreate(){
      if (this.userRoles.includes('location.create')){
        return true
      } else {
        return false
      }
    },
  },
  created() {
    this.fetchLocations();
  },
};
</script>

<template>
  <div>
    <h4 class="title">Create Location</h4>
    <b-row class="mt-3">
      <b-col cols="12" md="12">
        <rm-input
          outlined
          type="text"
          v-model="form.name"
          label="Name Of Location"
          required
          @blur="$v.form.name.$touch()"
        >
        </rm-input>
        <template v-if="$v.form.name.$error">
          <p v-if="!$v.form.name.required" class="error form-error">
            Location Name is required
          </p></template
        >
      </b-col>
      <b-col cols="12" md="12">
        <rm-input
          outlined
          type="text"
          v-model="form.address"
          label="Address"
          required
          @blur="$v.form.address.$touch()"
        >
        </rm-input>
        <template v-if="$v.form.address.$error">
          <p v-if="!$v.form.address.required" class="error form-error">
            Address is required
          </p></template
        >
      </b-col>
      <!-- <b-col cols="12" md="12">
        <rm-file-input
          outlined
          type="text"
          v-model="form.banner"
          label="Banner"
        />
      </b-col> -->
      <b-col cols="12" md="6">
        <rm-input
          outlined
          type="text"
          v-model="form.city"
          label="City"
          @blur="$v.form.city.$touch()"
        >
        </rm-input>
        <template v-if="$v.form.city.$error">
          <p v-if="!$v.form.city.required" class="error form-error">
            City is required
          </p></template
        >
      </b-col>
      <b-col cols="12" md="6">
        <label class="label text-dark text-sm" for="state-dropdown">LGA</label>
        <div class="select-box">
          <select
            v-model="form.lga"
            id="state-dropdown"
            class="my-select"
            required
            @blur="$v.form.lga.$touch()"
          >
            <option selected disabled class="text-gray-100"></option>
            <option
              class="py-0 mt-0 mb-0"
              v-for="(option, index) in lgaOptions"
              :value="option.name"
              :key="index"
            >
              {{ option.name }}
            </option>
          </select>
          <b-icon icon="caret-down-fill"></b-icon>
        </div>
        <p
          v-if="$v.form.lga.$anyError"
          class="error form-error"
          style="margin-top: 1rem"
        >
          LGA is required
        </p>
      </b-col>
      <b-col cols="12" md="6">
        <label class="label text-dark text-sm" for="state-dropdown"
          >State</label
        >
        <div class="select-box">
          <select
            v-model="form.state"
            id="state-dropdown"
            class="my-select"
            required
            @blur="$v.form.state.$touch()"
          >
            <option selected disabled class="text-gray-100"></option>
            <option
              class="py-0 mt-0 mb-0"
              v-for="(option, index) in states"
              :value="option.name"
              :key="index"
            >
              {{ option.name }}
            </option>
          </select>
          <b-icon icon="caret-down-fill"></b-icon>
        </div>
        <p
          v-if="$v.form.state.$anyError"
          class="error form-error"
          style="margin-top: 1rem"
        >
          State is required
        </p>
      </b-col>
      <b-col cols="12" md="6">
        <label class="label text-dark text-sm" for="state-dropdown"
          >Property Type</label
        >
        <div class="select-box">
          <select
            v-model="form.property_type"
            id="state-dropdown"
            class="my-select"
            required
            @blur="$v.form.state.$touch()"
          >
            <option selected disabled class="text-gray-100"></option>
            <option
              class="py-0 mt-0 mb-0"
              v-for="(property, index) in propertyTypes"
              :value="property.id"
              :key="index"
            >
              {{ property.name }}
            </option>
          </select>
          <b-icon icon="caret-down-fill"></b-icon>
        </div>
        <p
          v-if="$v.form.property_type.$anyError"
          class="error form-error"
          style="margin-top: 1rem"
        >
          Property Type is required
        </p>
      </b-col>
      <b-col cols="12" md="6">
        <label class="label text-dark text-sm" for="state-dropdown"
          >Property Category</label
        >
        <div class="select-box">
          <select
            v-model="form.property_category"
            id="state-dropdown"
            class="my-select"
            required
            @blur="$v.form.state.$touch()"
          >
            <option selected disabled class="text-gray-100"></option>
            <option
              class="py-0 mt-0 mb-0"
              v-for="(category, index) in ProductCategories"
              :value="category.id"
              :key="index"
            >
              {{ category.name }}
            </option>
          </select>
          <b-icon icon="caret-down-fill"></b-icon>
        </div>
        <p
          v-if="$v.form.property_category.$anyError"
          class="error form-error"
          style="margin-top: 1rem"
        >
          Property Category is required
        </p>
      </b-col>
      <b-col cols="12" md="6">
        <rm-input
          readonly
          outlined
          type="text"
          v-model="form.country"
          label="Country"
          required
        />
      </b-col>
      <b-col cols="12" md="6">
        <rm-input
          outlined
          type="text"
          v-model="form.unit_price"
          label="Unit Price"
          required
          @blur="$v.form.unit_price.$touch()"
        >
        </rm-input>
        <template v-if="$v.form.unit_price.$error">
          <p v-if="!$v.form.unit_price.required" class="error form-error">
            Unit Price is required
          </p>
          <p v-if="!$v.form.unit_price.integer" class="error form-error">
            Input must be an integer
          </p>
        </template>
      </b-col>

      <b-col cols="12" md="6">
        <rm-upload @completed="setBannerUrl" v-model="form.images" @blur="$v.form.images.$touch()" />
        <template v-if="$v.form.images.$error">
          <p v-if="!$v.form.images.required" class="error form-error">
            Image upload is required
          </p>
        </template>
      </b-col>

      <b-col cols="12">
        <rm-text-area
          outlined
          :rows="5"
          type="text"
          v-model="form.description"
          label="Description"
        >
        </rm-text-area>
      </b-col>
    </b-row>
    <b-row align-h="end" class="mt-lg-5 mt-0 pt-lg-5 pt-0">
      <b-col cols="2" class="mb-4">
        <rm-base-button
          @click="close"
          v-if="!isLoading"
          class="ml-auto"
          text="Cancel"
          bgColor="grey"
          textColor="black"
        ></rm-base-button>
      </b-col>
      <b-col cols="2" class="mb-4">
        <rm-base-button
          :disableButton="$v.$invalid"
          :loadingButton="isLoading"
          @click="createLocation"
          class="ml-auto"
          text="Create"
          bgColor="primary"
          textColor="white"
        ></rm-base-button>
      </b-col>
    </b-row>
    <!-- <p v-if="$v.$anyError" class="error big-center-text">
      Please fill out required field(s)
    </p> -->
  </div>
</template>

<script>
import { required, integer } from 'vuelidate/lib/validators';

import RmTextArea from '../../../components/global/RmTextArea.vue';
import countries from '@/utils/countries.json';
import states from '@/utils/states.json';
import lgas from '@/utils/lgas.json';
import RmUpload from './RmUpload.vue';

export default {
  components: { RmTextArea, RmUpload },
  data() {
    return {
      isLoading: false,
      countries,
      states,
      lgas,
      lgaOptions: [],
      form: {
        name: null,
        description: null,
        state: null,
        lga: null,
        city: '',
        country: 'Nigeria',
        unit_price: null,
        address: null,
        images: null,
        property_type: null,
        property_category: null,
        // banner: null,
      },
      // This fix would only work in production and can break if there are
      // changes to the data base
      propertyTypes: [
        {
          name: "Property Flex",
          id: "cf4ef0db-7288-449d-a193-135714ddc85a",
        },
        {
          name: "Homeworth Spaces",
          id: "5e04711f-8563-4463-b64e-5743ba6c201e"
        },
        {
          name: "Land X",
          id: "7657cd78-ee9f-43cf-a593-72a494775df4"
        }
      ],
      ProductCategories: [
        {
          name: "Land",
          id: "af7f5501-7886-428e-90d7-ea9b4dd09f06"
        },
        {
          name: "Co-ownership",
          id: "f3199737-0367-4662-b4ab-1f0350891682"
        }
      ]
    };
  },

  validations: {
    form: {
      name: { required },
      state: { required },
      lga: { required },
      city: { required },
      country: { required },
      unit_price: { required, integer },
      address: { required },
      images: { required },
      property_type: { required },
      property_category: { required }
    },
  },
  computed: {
    // states() {
    //   return countries.find((country) => country.name == this.form.country)
    //     ?.states;
    // },
  },
  watch: {
    'form.state'(value) {
      const selectedState = states.find((state) => state.name == value);
      const stateId = selectedState ? selectedState.id : null;
      this.lgaOptions = lgas.filter((lga) => lga.state_id == stateId);
    },
  },
  methods: {
    setBannerUrl(url) {
      this.form.images = url;
      // console.log(url);
    },
    close() {
      Object.assign(this.$data, this.$options.data.apply(this));

      this.$bvModal.hide('modal-lg-new-location');
    },
    async createLocation() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.dispatch('toast', {
          variant: 'error',
          message: 'Please fill all required fields',
        });
        return;
      }
      const custom_UUID = {
        category_id: "f3199737-0367-4662-b4ab-1f0350891682",
        product_type_id: "5e04711f-8563-4463-b64e-5743ba6c201e"
      }
      const loader = this.$loading.show();
      try {
        const body = {
          name: this.form.name,
          description: this.form.description,
          state: this.form.state,
          lga: this.form.lga,
          city: this.form.city,
          country: 'Nigeria',
          unit_price: Number(this.form.unit_price) * 100,
          address: this.form.address,
          images: this.form.images,
          category_id: custom_UUID.category_id,
          product_type_id: custom_UUID.product_type_id
        };

        await this.$store.dispatch('locations/createLocation', body);
        loader.hide();
        this.$emit('completed');
        this.close();
      } catch (error) {
        loader.hide();
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
}
.select-box {
  position: relative;
  display: flex;
  // height: 41%;
  border: 1px solid rgba(112, 112, 112, 0.432);
  border-radius: 0.25em;

  // .select-box select {
  //   border: 0;
  //   width: 100%;
  //   outline: 0;
  // }

  svg {
    position: absolute;
    right: 5%;
    bottom: 25%;
  }

  label {
    margin-bottom: 0.25rem;
  }

  .my-select {
    appearance: none;
    height: 100%;
    width: 100%;
    display: grid;
    // min-width: 15ch;
    // max-width: 30ch;
    border: 1px solid rgba(112, 112, 112, 0.432);
    border-radius: 0.25em;
    border: 0;
    padding: 0.8em 1.25em;
    font-size: 0.9rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  }
}

.form-error {
  margin-top: -1rem;
}

.big-center-text {
  font-size: 1.6rem;
  // font-weight: 600;
  text-align: center;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"location-card bg-white rounded-md overflow-hidden mb-5",on:{"click":_vm.handleClick}},[_c('div',{staticClass:"location-card__banner w-100",style:({
        backgroundImage: `url(${_vm.image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: 'var(--primary)',
        height: '10rem',
      })}),_c('div',{staticClass:"location-card__details p-4 h-100"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"location-name text-sm font-weight-bold text-black"},[_vm._v(" "+_vm._s(_vm.location.name)+" ")])]),_c('div',{staticClass:"d-flex align-items-center mt-2"},[_c('div',{staticClass:"bg-tertiary--light py-1 px-2 text-sm-2 rounded-sm mr-2"},[_vm._v(" "+_vm._s(_vm.location.status)+" ")]),_c('div',{staticClass:"bg-tertiary--light py-1 px-2 text-sm-2 rounded-sm mr-2"},[_vm._v(" 0 plot sold ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }